<template>
  <v-app-bar
    id="default-app-bar"
    elevate-on-scroll
    app
    dark
    class="v-bar--underline"
    color="gray"
    :clipped-left="!$vuetify.rtl"
    :clipped-right="$vuetify.rtl"
    height="70"
    flat
  >
    <!-- <v-btn
      depressed
      large
      retain-focus-on-click

      @click="$vuetify.goTo(0)"
    > -->
    <strong class="mr-1 font-weight-black">Housing</strong>
    <button class="primary--text" @click="navigateToHome">Eden</button>
    <pre>&nbsp;&nbsp;&nbsp;</pre>
    <!-- </v-btn> -->
    <v-spacer />
    <!-- {{ $t("message.language") }}: -->
    <!-- <v-chip
      @click="handleClick_changeLanguage('en')"
    >
      En
    </v-chip>
    <v-chip
      @click="handleClick_changeLanguage('ja')"
    >
      Ja
    </v-chip>
    <v-chip
      @click="handleClick_changeLanguage('ko')"
    >
      Ko
    </v-chip> -->

    <!-- '' -->
    <div>
      <v-select
        v-model="select"
        hide-details
        :items="country"
        :label="$t('message.language')"
        solo
        prepend-inner-icon="mdi-web"
        @input="handleClick_changeLanguage($event)"
      />
    </div>

    <!-- <v-btn
      icon
      dark
      color="#E91E63"
      @click="scrollTop"
    >
      <v-icon x-large>
        mdi-chevron-up-circle
      </v-icon>
    </v-btn> -->
    <!-- <v-toolbar-title
      class="font-weight-light text-h5"
      v-text="name"
    /> -->

    <div v-if="!$auth.loading">
      <!-- show login when not authenticated -->
      <!-- ログインボタン (認証されていない場合) -->
      <v-btn
        v-if="!$auth.isAuthenticated"
        color="primary"
        rounded
        prepend-icon="mdi-login"
        stacked
        variant="text"
        outlined
        elevation-5
        small
        @click="handleLoginClick"
      >
        <!-- スマホ以外の場合に「ログイン」のメッセージを表示 -->
        <template v-if="!$vuetify.breakpoint.xsOnly">
          <v-icon left>mdi-login</v-icon> ログイン
        </template>

        <!-- スマホの場合はアイコンだけ表示 -->
        <template v-else>
          <v-icon>mdi-login</v-icon>
        </template>
      </v-btn>

      <!-- show logout when authenticated -->
    </div>

    <!-- ボタンを押すとポップアップを開く -->
    <v-btn
      v-if="$auth.isAuthenticated"
      class="rounded-btn"
      elevation-5
      color="primary"
      fab
      @click="dialog = true"
    >
      <v-img
        :src="modifiedProfilePicture"
        alt="User Profile"
        class="profile-img"
        contain
        width="45"
        height="45"
      />
    </v-btn>

    <!-- ProfileDialog コンポーネントの呼び出し -->
    <profile :dialog.sync="dialog" />

    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <!-- v-text="name" -->

    <!-- <v-spacer /> -->

    <!-- <default-search class="hidden-sm-and-down" /> -->

    <!-- <default-go-home /> -->

    <!-- <default-notifications /> -->

    <!-- <default-account /> -->
    <!-- ログインヒントダイアログ -->
    <v-dialog v-model="showLoginDialog" max-width="400px">
      <v-card>
        <v-card-title class="text-h5">ログインのヒント</v-card-title>
        <v-card-text>
          先にブラウザでTwitterにログインしておくとスムーズにログインできます。
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="openTwitter"
            >別タブでTwitterを開く</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="login">ログイン</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
  // Utilities
  import { get, sync } from 'vuex-pathify';
  import Profile from '../../views/Profile.vue';

  export default {
    name: 'DefaultBar',

    components: {
      Profile,
      // DefaultAccount: () => import(
      //   /* webpackChunkName: "default-account" */
      //   './widgets/Account'
      // ),
      DefaultDrawerToggle: () =>
        import(
          /* webpackChunkName: "default-drawer-toggle" */
          './widgets/DrawerToggle'
        ),
      // DefaultGoHome: () => import(
      //   /* webpackChunkName: "default-go-home" */
      //   './widgets/GoHome'
      // ),
      // DefaultNotifications: () => import(
      //   /* webpackChunkName: "default-notifications" */
      //   './widgets/Notifications'
      // ),
      // DefaultSearch: () => import(
      //   /* webpackChunkName: "default-search" */
      //   './widgets/Search'
      // ),
    },
    data: function () {
      return {
        showLoginDialog: false, // ログインダイアログの表示状態
        uploadedImage: null, // アップロードされた画像を保持
        userInfo: null, // ユーザー情報
        housingTweetURL: '',
        furnitureTweetURL: '',
        housingYouTubeURL: '',
        glamourRecipeTweetURL: '',
        dialog: false, // ダイアログの開閉状態
        country: ['en', 'ja', 'ko', 'zh-CHS', 'zh-CHT'],
        select: this.$i18n.locale,
      };
    },

    computed: {
      ...sync('app', ['drawer', 'mini']),
      name: get('route/name'),
      // プロフィール画像のサイズを置き換えたものを返す
      modifiedProfilePicture() {
        // 現在のURLが `_normal.jpg` で終わっている場合
        if (this.$auth.user && this.$auth.user.picture) {
          return this.$auth.user.picture.replace('_normal', '_400x400');
        }
        return ''; // 何かデフォルトの画像にフォールバック
      },
    },
    created: function () {
      // ユーザー情報を取得
      this.userInfo = this.$auth.user;

      // console.log(this.$i18n.locale)
      if (this.$i18n.locale === 'zh') {
        this.select = 'zh-CHT';
        this.handleClick_changeLanguage(this.select);
      }
    },
    methods: {
      handleLoginClick() {
        // モバイル判定
        if (this.$vuetify.breakpoint.xsOnly) {
          // モバイルの場合ダイアログを表示
          this.showLoginDialog = true;
        } else {
          // モバイルでない場合は通常のログイン処理
          this.login();
        }
      },
      openTwitter() {
        // Twitterのブラウザ専用URLを使用する
        const twitterLoginUrl = 'https://mobile.twitter.com/login';
        window.open(twitterLoginUrl, '_blank', 'noopener,noreferrer');
      },
      login() {
        console.log(
          'window.location.hash:' + window.location.hash.substring(1)
        );
        this.$auth.loginWithRedirect({
          audience: 'https://housingeden.jp.auth0.com/api/v2/',
          scope: 'profile read:users read:current_user read:user_idp_tokens',
          appState: {
            targetUrl: window.location.hash.substring(1), // ハッシュ部分を取り除いてパスを取得
          },
        });
      },
      navigateToHome() {
        this.$router.push('/').catch((err) => {
          // 同じページに対するエラーを無視する
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
        // ここでページをリフレッシュまたはデータをリセットするロジックを追加する
      },
      reloadAndScrollTop() {
        // ページをリロードします（もし必要なら）
        location.reload();
        // ページのトップにスクロールします
        this.$vuetify.goTo(0);
      },
      // scrollTop: function () {
      //   window.scrollTo({
      //     top: 0,
      //     behavior: 'smooth',
      //   })
      // },
      handleClick_changeLanguage(event) {
        if (event === 'zh-CHS') {
          this.$i18n.locale = 'zh-CN';
        } else if (event === 'zh-CHT') {
          this.$i18n.locale = 'zh-TW';
        } else {
          this.$i18n.locale = event;
        }
      },
      reload() {
        window.location.reload(true);
        console.log('reload');
      },
    },
  };
</script>

<style scoped>
  .rounded-btn {
    padding: 0;
    border-radius: 50%; /* ボタン自体を丸くする */
    width: 45px;
    height: 45px;
    min-width: 45px; /* ボタンの最小幅 */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* ボタン内で画像がボタン外に溢れないようにする */
  }

  .profile-img {
    border-radius: 50%; /* 画像を丸くする */
  }
</style>
